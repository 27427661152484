
import { Logotype, Contacts } from '@packages/kit';

import React  from 'react';
import { Link } from 'react-router-dom';

import cn from 'classnames';

import styles from './default.module.scss';
import visaImg from './ic-visa.png';
import mcImg from './ic-mc.png';
import mirImg from './ic-mir.png';


function Footer() {

  return (
    <section className={styles['footer']}>
      <div className={styles['container']}>
        <div className={styles['section']}>

          <div className={styles['info']}>
            <Logotype />
            <div className={styles['info__address']}>
              <div className={styles['info__address-street']}>143003, Московская область, г. Одинцово,<br />Б-р Любы Новоселовой, д. 18, помещ. IX/ком 4</div>
              <div className={styles['info__address-mail']}>
                <Contacts />
              </div>
            </div>
          </div>

          <ul className={styles['images']}>
            <li className={styles['images__item']}><img className={styles['icon']} src={visaImg} alt={'visa'} /></li>
            <li className={styles['images__item']}><img className={styles['icon']} src={mcImg} alt={'mastercard'} /></li>
            <li className={styles['images__item']}><img className={styles['icon']} src={mirImg} alt={'mir'} /></li>
          </ul>

          <ul className={styles['links']}>
            <li className={cn(styles['links__item'], styles['mobile-hide'])}>
              <Link className={styles['links__item-link']} to={process.env['PUBLIC_URL'] + '/pravila-vozvrata'}>Правила возврата товаров</Link>
            </li>
            <li className={cn(styles['links__item'], styles['mobile-hide'])}>
              <Link className={styles['links__item-link']} to={process.env['PUBLIC_URL'] + '/kak-sdelat-zakaz'}>Как можно сделать заказ</Link>
            </li>
            <li className={styles['links__item']}>
              <Link className={styles['links__item-link']} to={process.env['PUBLIC_URL'] + '/trebovaniya-k-prodavtsam'}>Требования к продавцам</Link>
            </li>
            <li className={styles['links__item']}>
              <Link className={styles['links__item-link']} to={process.env['PUBLIC_URL'] + '/company'}>О компании</Link>
            </li>
            <li className={cn(styles['links__item'], styles['mobile-hide'])}>
              <Link className={styles['links__item-link']} to={process.env['PUBLIC_URL'] + '/pravila-prodazhi'}>Правила продажи товаров через сеть Интернет</Link>
            </li>
            <li className={styles['links__item']}>
              <Link className={styles['links__item-link']} to={process.env['PUBLIC_URL'] + '/oferta'}>Оферта на оказание услуг</Link>
            </li>
            <li className={cn(styles['links__item'], styles['mobile-hide'])}/>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default Footer;
